.dx-toolbar-items-container  {
  background: #f2f3f8;
}
.dx-datagrid-header-panel {
  background: #f2f3f8;
}

.dx-pager {
  background: #f2f3f8;
}

.dx-toolbar-items-container{
    background: none;
}

.dx-datagrid-header-panel{
    background: none;
}

#dropdown-item-button, #dropdown-item-button-2, .dropdown-menu{
    width: 100%;
    margin-bottom: 10px;
}
